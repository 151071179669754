
/******************************************
* Metric Modal Chart (Views, Downloads, Citations)
********************************************/

/* When there is no data ...*/

.linechart .metric-chart text {
	fill: #565656;
	font-size: 9px;
	font-family: Helvetica, Arial, "sans serif";
}

.linechart .metric-chart text.no-data {
	font-size: 16px;
	font-weight: 100;
	fill:#d0d0d0;
}

.linechart .metric-chart rect.no-data {
	fill: #f5f5f5;
}

/* When there is data ...*/

/* CB: padding to display better on bl.ocks.org */
.linechart {
	padding: 50px 0 50px 0;
}

.linechart .metric-chart rect.plot-background{
	fill: white;
}

.linechart .metric-chart path.line {
	fill: none;
	stroke: #00AA8D; /* default, changed in each theme */
	stroke-width: 1.5px;
	clip-path: url(#clip);
}

.linechart .metric-chart path.area {
	fill: #00AA8D; /* CB default, changed in each theme */
	opacity: 0.6;
	clip-path: url(#clip);
}

.linechart .metric-chart .axis {
	shape-rendering: crispEdges;
}

.linechart .metric-chart .x.axis .domain{
	display:none;
}

.linechart .metric-chart .x.axis line {
	stroke: white;
	opacity: 0.4;
}

.linechart .metric-chart .context .x.axis line {
	display: none;
}

.linechart .metric-chart .y.axis .domain{
	display: none;
}

.linechart .metric-chart .y.axis.title{
	font-size: 13px;
	font-weight: 100;
}

.linechart .metric-chart .y.axis line {
	stroke: #565656;
	stroke-dasharray: 2,2;
	stroke-opacity: 0.3;
}

.linechart .metric-chart .brush .extent {
  fill-opacity: .07;
  shape-rendering: crispEdges;
  clip-path: url(#clip);
}

.linechart .metric-chart rect.pane {
	cursor: move;
	fill: none;
	pointer-events: all;
}
/* brush handles  */
.linechart .metric-chart .resize .handle {
	fill: #555;
}

.linechart .metric-chart .resize .handle-mini {
	fill: white;
    stroke-width: 1px;
    stroke: #555;
}

.linechart .metric-chart .scale_button {
	cursor: pointer;
}

.linechart .metric-chart .scale_button rect {
	fill: #eaeaea;
}
.linechart .metric-chart .scale_button:hover text {
	fill: #417DD6;
	transition: all 0.1s cubic-bezier(.25,.8,.25,1);
}

.linechart .metric-chart text#displayDates  {
	font-weight: bold;
}

